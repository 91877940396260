<template>
       <div>

      <ContactoModal v-show="isModalVisibleContacto == true" @close="closeModalContacto" :option="option" >
        <template v-slot:header>
        </template>

        <template v-slot:body> 
        </template>
      </ContactoModal>
      
        <div class="footer">
          <div class="column uno">
           <!-- <div class="historia">
              <h3 :class="'foo-titulo'+' '+isNav()">HISTORIA</h3>
              <h4 class="foo-parrafo">
                El equipo nació  cuando el recién ascendido y campeón 
                de la Tercera División de México, Poblado Miguel Alemán 
                FC, no tuvo la aprobación de parte de la Federación Mexicana
                 de Futbol para participar en la Comisaría Miguel Alemán,
                  pequeña localidad ubicada en la zona rural del municipio
                   de Hermosillo que no contaba con los requerimientos 
                   mínimos de infraestructura, hotelería,
                    transporte, etc.</h4>
            </div>-->
            <div class="sec-suscr">
              <h3 :class="'foo-titulo'+' '+isNav()">NEWSLETTER</h3>
              <h4 class="foo-parrafo">Recibe noticias y promociones 
                exclusivas directo a tu correo electrónico.</h4>
            
            <form
                  @submit.prevent="
                    submit(
                    email_form
                    )
                  "
                >
                  <div class="group-form-suscr">
                    <div>
                      <!--<label class="labelEhome" for="inputEhome">Email</label> -->
                      <b-form-input   v-model="email_form"
                       required id="inputEhome" type="email" 
                       placeholder="CORREO ELECTRÓNICO" />
                    </div>
                    <div >
                      <b-btn 
                        variant="outline-secondary"
                        id="btn-suscr"
                        type="submit"
                        :placeholder="'CORREO ELECTRONICO'"
                        >SUSCRÍBETE AQUI</b-btn
                      >
                  
                  </div>
                  </div>

                </form>
                  <!---Mensaje de error o exito.-->
                  <div v-if="this.status != ''" class="message-subscriptor">
                        <p>{{this.msg}} <span @click="salir()">&#10006;</span> </p>
                  </div>
            </div>
          </div>

          <div class="column due">
            <div class="col-column uno">
              <h3 :class="'foo-titulo'+' '+isNav()">MENÚ</h3>
              <a href="/">
                  <h4 class="list-col">INICIO</h4>
              </a>
              <a href="/clasificaciones">
                  <h4 class="list-col">TABLA GENERAL</h4>
              </a>
              <a href="/blog">
                  <h4 class="list-col">NOTICIAS</h4>
              </a>
              <a href="/galeria">
                <h4 class="list-col">GALERÍAS</h4>
              </a>
              <a href="/filosofia">
                <h4 class="list-col">FILOSOFÍA</h4>
              </a>
              <a href="/estadio">
                <h4 class="list-col">ESTADIO</h4>
              </a>
              <a href="/videos">
                <h4 class="list-col">VIDEOS</h4>
              </a>
              <a href="/boletos">
                  <h4 class="list-col">BOLETOS</h4>
              </a>
              <a href="/politica-privacidad">
                  <h4 class="list-col">TIENDA</h4>
              </a>
            </div>
            <div class="col-column due">
              <div class="list-col-cont">
                <a @click="showModalContacto('contacto')">
                  <h4 class="list-col">CONTACTO</h4>
                </a>
                <a href="/calendarios">
                  <h4 class="list-col">CALENDARIO Y RESULTADOS</h4>
                </a>
                <a href="/prensa">
                  <h4 class="list-col">PRENSA</h4>
                </a>
                <a @click="showModalContacto('comercial')">
                  <h4 class="list-col">COMERCIAL</h4>
                </a>
                <a href="/politica-privacidad">
                  <h4 class="list-col">POLÍTICA DE PRIVACIDAD</h4>
                </a>
                <a href="/terminos-y-condiciones">
                  <h4 class="list-col">TÉRMINOS Y CONDICIONES</h4>
                </a>
                <!--<a href="#">
                  <h4 class="list-col">PATROCINADOR</h4>
                </a>-->
              </div>
              <div class="redes-soc-col">
                <a href="https://www.facebook.com/CimarronesFC/" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/fb-foo.webp" />
                      <source srcset="../assets/ins-foo.png" type="image/png" />
                      <img class="btn-sm-col" src='../assets/webp/fb-foo.webp' alt="icon" width="4" height="10">
                    </picture>
                </div>
                </a>
                <a href="https://www.instagram.com/cimarronesfc/" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/ins-foo.webp" />
                      <source srcset="../assets/ins-foo.png" type="image/png" />
                      <img class="btn-sm-col" src='../assets/webp/ins-foo.webp' alt="icon" width="10" height="10">
                    </picture>
                  </div>
                </a>
                <a href="https://mx.linkedin.com/company/cimarronessonora" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/lin-foo.webp" />
                      <source srcset="../assets/lin-foo.png" type="image/png" />
                      <img class="btn-sm-col" src='../assets/webp/lin-foo.webp' alt="icon" width="10" height="10">
                    </picture>
                  </div>
                </a>
                <a href="https://twitter.com/CimarronesFC" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/tw-foo.webp" />
                      <source srcset="../assets/tw-foo.png" type="image/png" />
                      <img class="btn-sm-col" src='../assets/webp/tw-foo.webp' alt="icon" width="12" height="10">
                    </picture>
                  </div>
                </a>
                <a href="https://www.youtube.com/channel/UCvPozguw0umKKLZIBH_jMnQ" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/yt-foo.webp" />
                      <source srcset="../assets/yt-foo.png" type="image/png" />
                      <img class="btn-sm-col" src='../assets/webp/yt-foo.webp' alt="icon" width="14" height="10">
                    </picture>
                  </div>
                </a>
                <a href="https://www.tiktok.com/@cimarronesfc?" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/tt-foo.webp" />
                      <source srcset="../assets/tt-foo.png" type="image/png" />
                      <img class="btn-sm-col" src='../assets/webp/tt-foo.webp' alt="icon" width="8" height="10">
                    </picture>
                  </div>
                </a>
              </div>
            </div>


            <div class="col-mob">
              <h3 :class="'foo-titulo'+' '+isNav()">MENÚ</h3>
              <div class="list-cont-foo">
              <div class="list-flex">
                <a href="/">
                    <h4 class="list-col">INICIO</h4>
                </a>
                <a href="/clasificaciones">
                  <h4 class="list-col">TABLA GENERAL</h4>
                </a>
                <a href="/noticias">
                    <h4 class="list-col">NOTICIAS</h4>
                </a>
                <a href="/galeria">
                  <h4 class="list-col">GALERÍAS</h4>
                </a>
              </div>
              <div class="list-flex">
                <a href="/videos">
                  <h4 class="list-col">VIDEOS</h4>
                </a>
                <a href="/tienda">
                  <h4 class="list-col">TIENDA</h4>
                </a>
                <a @click="showModalContacto('contacto')">
                  <h4 class="list-col">CONTACTO</h4>
                </a>
                <a href="/calendarios">
                  <h4 class="list-col">CALENDARIO Y RESULTADOS</h4>
                </a>
                <!--<a href="/boletos">
                    <h4 class="list-col">BOLETOS</h4>
                </a>
                <a href="/tienda">
                    <h4 class="list-col">TIENDA</h4>
                </a>
                <a @click="showModalContacto('comercial')">
                  <h4 class="list-col">COMERCIAL</h4>
                </a>
                <a href="/calendarios">
                  <h4 class="list-col">CALENDARIO Y RESULTADOS</h4>
                </a>-->
              </div>
              <div class="list-flex">
                <a href="/prensa">
                  <h4 class="list-col">PRENSA</h4>
                </a>
                <a @click="showModalContacto('comercial')">
                  <h4 class="list-col">COMERCIAL</h4>
                </a>
                <a href="/politica-privacidad">
                  <h4 class="list-col">POLÍTICA DE PRIVACIDAD</h4>
                </a>
                <a href="/terminos-y-condiciones">
                  <h4 class="list-col">TÉRMINOS Y CONDICIONES</h4>
                </a>
                <!--<a href="#">
                  <h4 class="list-col">CALENDARIO Y RESULTADOS</h4>
                </a>
                <a href="#">
                  <h4 class="list-col">PATROCINADOR</h4>
                </a>-->
              </div>
            </div>
              
              <div class="redes-soc-col">
                <a href="https://www.facebook.com/CimarronesFC/" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/fb-foo.webp" />
                      <source srcset="../assets/fb-foo.png" type="image/png" />
                      <img class="btn-sm-col" 
                      src='../assets/fb-foo.png' alt="icon" width="4" height="10">  
                    </picture>
                  </div>
                </a>
                <a href="https://www.instagram.com/cimarronesfc/" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/ins-foo.webp" />
                      <source srcset="../assets/ins-foo.png" type="image/png" />
                      <img class="btn-sm-col" src="../assets/webp/ins-foo.webp" alt="icon" width="10" height="10">
                    </picture>
                  </div>
                </a>
                <a href="https://mx.linkedin.com/company/cimarronessonora" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/lin-foo.webp" />
                      <source srcset="../assets/lin-foo.png" type="image/png" />
                      <img class="btn-sm-col" src="../assets/webp/lin-foo.webp" alt="icon" width="10" height="10">
                    </picture>
                  </div>
                </a>
                <a href="https://twitter.com/CimarronesFC" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/tw-foo.webp" />
                      <source srcset="../assets/tw-foo.png" type="image/png" />
                      <img class="btn-sm-col" src="../assets/webp/tw-foo.webp" alt="icon" width="12" height="10">
                    </picture>
                  </div>
                </a>
                <a href="https://www.youtube.com/channel/UCvPozguw0umKKLZIBH_jMnQ" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/yt-foo.webp" />
                      <source srcset="../assets/yt-foo.png" type="image/png" />
                      <img class="btn-sm-col" src='../assets/webp/yt-foo.webp' alt="icon" width="14" height="10">
                    </picture>
                  </div>
                </a>
                <a href="https://www.tiktok.com/@cimarronesfc?" target="_blank" rel="noopener noreferrer">
                  <div class="btn-sm-cont">
                    <picture>
                      <source type="image/webp" srcset="../assets/webp/tt-foo.webp" />
                      <source srcset="../assets/tt-foo.png" type="image/png" />
                      <img class="btn-sm-col" src='../assets/webp/tt-foo.webp' alt="icon" width="8" height="10">
                    </picture>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="patrocinadores">
          <div class="patro-col uno" v-if="Array.isArray(sponsorfooter) ">
            <a v-for="(sf,i) in sponsorfooter" :key="i" :href="sf.url"  target="_blank" rel="noopener noreferrer"> 
              <img :src="url+'/get-sponsor-image/'+isWebp(regexImg(sf.image), sf.image)" alt="patro" class="patro-logo uno"
              width="155" height="50">
            </a>
          </div>
          <div class="patro-col due" v-if="Array.isArray(sponsorfooter2) ">
            <a v-for="(sf,i) in sponsorfooter2"  :key="i" :href="sf.url" target="_blank" rel="noopener noreferrer">
              <img :src="url+'/get-sponsor-image/'+isWebp(regexImg(sf.image), sf.image)" alt="patro" class="patro-logo tre"
              width="122" height="39">
            </a>
          </div>
          <div class="patro-col uno" v-if="Array.isArray(sponsorfooter3) ">
            <a v-for="(sf,i) in sponsorfooter3" :key="i" :href="sf.url" target="_blank" rel="noopener noreferrer">
              <img :src="url+'/get-sponsor-image/'+isWebp(regexImg(sf.image), sf.image)" alt="patro" class="patro-logo sette"
              width="84" height="27">
            </a>
        
          </div><div class="patro-col tre" v-if="Array.isArray(sponsorfooter4) " >    <!-- Si cumple con que es un array (osea que si trae)-->
        
               <a v-for="(sf,i) in sponsorfooter4" :key="i" :href="sf.url" target="_blank" rel="noopener noreferrer">
              <img :src="url+'/get-sponsor-image/'+isWebp(regexImg(sf.image), sf.image)" alt="patro" class="patro-logo nueve"
              width="59" height="32">
            </a>
          
           
          </div>
        </div>

        <div class="foot-cimarrones">
                    <picture>
                      <source type="image/webp" srcset="../assets/Cimarrones-mx.webp" />
                      <source srcset="../assets/Cimarrones-mx.png" type="image/png" />
                      <img src="../assets/Cimarrones-mx.webp" 
                      alt="cimarrones" class="logo-cima-foot" width="147" height="147">

                    </picture>
          <!--<div class="list-foot">
            <a href="#">
              <h5>CONDICIONES GENERALES DE CONTRATACIÓN</h5>
            </a>
            <a href="#">
              <h5>FAQ</h5>
            </a>
            <a href="#">
              <h5>ATENCIÓN A CLIENTES</h5>
            </a>
            <a href="#">
              <h5>AVISO LEGAL</h5>
            </a>
            <a href="#">
              <h5>COOKIES</h5>
            </a>
            <a @click="showModalContacto('contacto')">
              <h5>CONTACTOS</h5>
            </a>
            <a href="#">
              <h5>PRENSA</h5>
            </a>
            <a href="#">
              <h5>LEY TRANSPARENCIA</h5>
            </a>
            <a href="/politica-privacidad">
              <h5 class="last">POLÍTICA DE PRIVACIDAD</h5>
            </a>
          </div>-->
          <h5 class="copyright">COPYRIGHT, TODOS LOS DERECHOS RESERVADOS.</h5>
        </div>
       </div>
</template>

<script>
import ContactoModal from "../components/ContactoModal.vue";
import {mapActions} from 'vuex';
export default {
  name:'Footer',
  components:{
    ContactoModal,
  },
  data: () => ({
    status:'',
    msg:'',
    email_form: '',
    isModalVisibleContacto: false,
    modalDataContacto: null,
    option:''
  }),
  created() {
    this.getInfoByFila();
    this.getInfoByFila2();
    this.getInfoByFila3();
    this.getInfoByFila4();
  },computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    sponsorfooter() {
      return this.$store.getters["sponsor_footer/data"];
    },
    sponsorfooter2() {
      return this.$store.getters["sponsor_footer/data2"];
    },
    sponsorfooter3() {
      return this.$store.getters["sponsor_footer/data3"];
    },
    sponsorfooter4() {
      return this.$store.getters["sponsor_footer/data4"];
    },
  },
  methods: {
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    ...mapActions('sponsor_footer', ['getInfoByFila']), 
    ...mapActions('sponsor_footer', ['getInfoByFila2']), 
    ...mapActions('sponsor_footer', ['getInfoByFila3']), 
    ...mapActions('sponsor_footer', ['getInfoByFila4']), 

        regexImg(txt){
          if(txt){
              let s = txt.split('.')
             return s[0]+'.webp'
          }
          return ''
         

        },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return webpImg; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return webpImg; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return webpImg; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return pngImg; // Safari
            }
    },
    isNav() {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return 'webp'; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return 'webp'; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return 'webp'; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return ''; // Safari
            }
    },

    getInfoByFila: async function() {
      let result = await this.$store.dispatch("sponsor_footer/getInfoByFila", {
        "option" : "sponsor_footer/fila", "name" : "FILA 1"
      });
      this.info = result;
      return result;
    },
    getInfoByFila2: async function() {
      let result = await this.$store.dispatch("sponsor_footer/getInfoByFila2", {
        "option" : "sponsor_footer/fila", "name" : "FILA 2"
      });
      this.info = result;
      return result;
    },
    getInfoByFila3: async function() {
      let result = await this.$store.dispatch("sponsor_footer/getInfoByFila3", {
        "option" : "sponsor_footer/fila", "name" : "FILA 3"
      });
      this.info = result;
      return result;
    },
    getInfoByFila4: async function() {
      let result = await this.$store.dispatch("sponsor_footer/getInfoByFila4", {
        "option" : "sponsor_footer/fila", "name" : "FILA 4"
      });
      this.info = result;
      return result;
    },

    showModalContacto(value) {
      this.option = value
      this.isModalVisibleContacto = true;
     /* this.modalDataTalla = data;*/
    },
    closeModalContacto() {
      this.isModalVisibleContacto = false;
      this.option =''
      /*this.modalDataTalla = null;*/
    },

    submit: async function (email) {
      let result = await this.$store.dispatch("main/addSuscriptor",{"email":email});
     
      if(result.status=='success'){
        this.msg='Gracias por subscribirte a nuestro boletín.'
        this.status ='success'
         
      }else{
        this.msg=result.message
         this.status ='error'
         
      }
    },
  
    salir: function () {
      this.status =''
      this.msg=''
      this.wait();
      
    },
   
  }
}
</script>