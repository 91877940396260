<template>
  <div class="content-hh moov color-bg-1">
    <div id="menu-sticky" @class="[isSticky ? stickyClass : '']">
      <div class="sec1-hh color-bg-10">
        <div class="columna-sec1-hh">
          <h3 class="mt1">SIGUIENTE PARTIDO</h3>
          <span class="linea"></span>
          <!-- PROXIMAMENTE HACER DINAMICO EL H3, TOMAR VALORES DE VARIABLES. -->
          <h3 v-if="jornadaProx2[0].statusMM !== 7" class="mt2"><!--jornadaProx2 !== 'No hay partidos' || jornadaProx2[0].statusMM !== 7-->
            <picture>
              <!--<source
                type="image/webp"
                :srcset="
                  url +
                  '/get-calendar-image/' +
                  regexImg(this.jornadaProx2[0].image)
                "
              />
              <source
                :srcset="
                  url + '/get-calendar-image/' + this.jornadaProx2[0].image
                "
                type="image/png"
              />
              <img
                class="iconpartido"
                width="33"
                height="38"
                :src="
                  url +
                  '/get-calendar-image/' +
                  regexImg(this.jornadaProx2[0].image)
                "
                alt="equipo"
              />-->
              <img
                class="iconpartido"
                width="33"
                height="38"
                :src="url + '/get-calendar-image/' + this.jornadaProx2[0].image"
                alt="equipo"
              />
            </picture>
            {{ this.jornadaProx2[0].opname }},
            {{ matchnameday(this.jornadaProx2[0].descrp) }}-{{
              matchday(this.jornadaProx2[0].descrp)
            }}, DE {{ matchmonth(this.jornadaProx2[0].descrp) }}
            {{
              hour(
                this.jornadaProx2Api,
                this.jornadaProx2[0].typegame  ) /*this.jornadaProx2[0].hr*/
            }}
            HRS
            <picture>
              <source type="image/webp" :srcset="iconarrow1" />
              <source :srcset="iconarrow" type="image/png" />
              <img
                class="iconarrow"
                :src="iconarrow1"
                width="74"
                height="7"
                alt="flecha"
              />
            </picture>
          </h3>
          <h3 class="no-matches" v-else>No hay partidos</h3>
        </div>
        <div class="columna-sec1-hh">
          <section v-if="jornadaProx2[0].statusMM !== 7"><!--jornadaProx2 !== 'No hay partidos' || jornadaProx2[0].statusMM !== 7-->
            <a
              v-if="this.jornadaProx2[0].type == 'Local'"
              class="comprar-boletos"
              :href="this.linkb"
              target="_blank"
              rel="noreferrer noopener"
              >COMPRA TUS BOLETOS AQUÍ</a
            >
          </section>

          <picture>
            <source type="image/webp" :srcset="iconpt1n2" />
            <source :srcset="iconpt1" type="image/png" />
            <a
              href="https://online.caliente.mx/page?member=cimarrones&campaign=DEFAULT&channel=DEFAULT&zone=60503210&lp=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="patrocinador1"
                width="92"
                height="46"
                :src="iconpt1n2"
                alt="patrocinio"
            /></a>
          </picture>
        </div>
      </div>

      <div class="sec2-hh">
        <div class="sec3-hh">
          <a href="/">
            <picture>
              <source type="image/webp" :srcset="logo1" />
              <source :srcset="logo" type="image/png" />
              <img
                class="logo-hh"
                :src="logo1"
                alt="flecha"
                width="59"
                height="65"
              />
            </picture>
          </a>
        </div>
        <div id="menu-hh">
          <ul class="menucito">
            <li><a href="/">INICIO</a></li>
            <li class="dd1">
              JUEGOS<picture>
                <source type="image/webp" :srcset="iconpolygon1" />
                <source :srcset="iconpolygon" type="image/png" />
                <img
                  class="iconpolygon"
                  :src="iconpolygon1"
                  alt="flecha"
                  width="10"
                  height="10"
                />
              </picture>
              <ul class="dropa">
                <li><a href="/calendarios"> Calendario y Resultados </a></li>
                <li><a href="/clasificaciones"> Tabla General </a></li>
              </ul>
            </li>
            <li class="dd2">
              EQUIPOS
              <picture>
                <source type="image/webp" :srcset="iconpolygon1" />
                <source :srcset="iconpolygon" type="image/png" />
                <img
                  class="iconpolygon"
                  :src="iconpolygon1"
                  alt="flecha"
                  width="10"
                  height="10"
                />
              </picture>
              <ul class="dropa2">
                <li><a href="/jugadores"> Expansión </a></li>
                <li><a href="/jugadores/premier"> Premier </a></li>
                <li><a href="/jugadores/tercera"> Tercera (TDP) </a></li>
              </ul>
            </li>
            <li class="dd3">
              <a class="unika" href="#ultimasNoticias"> NOTICIAS</a>
              <picture>
                <source type="image/webp" :srcset="iconpolygon1" />
                <source :srcset="iconpolygon" type="image/png" />
                <img
                  class="iconpolygon"
                  :src="iconpolygon1"
                  alt="flecha"
                  width="10"
                  height="10"
                />
              </picture>
              <ul class="dropa3">
                <li><a href="/blog/expansion"> Expansión </a></li>
                <li><a href="/blog/fuerzas-basicas"> Fuerzas Básicas </a></li>
                <li><a href="/blog/eventos"> Eventos </a></li>
                <!--<li><a href="/galeria">Galerías de fotos</a></li>-->
              </ul>
            </li>
            <li><a href="/galeria"> GALERÍA </a></li>
            <li><a href="/videos"> VIDEOS </a></li>
            <li class="dd4">
              CLUB
              <picture>
                <source type="image/webp" :srcset="iconpolygon1" />
                <source :srcset="iconpolygon" type="image/png" />
                <img
                  class="iconpolygon"
                  :src="iconpolygon1"
                  alt="flecha"
                  width="10"
                  height="10"
                />
              </picture>
              <ul class="dropa4">
                <!--  <li>Historia</li> -->
                <li><a href="/filosofia">Filosofía</a></li>
                <li><a href="/filosofia#Historia">Historia</a></li>

                <li><a href="/estadio"> Estadio</a></li>
                <!--<li>Directiva</li>
                                    <li>Responsabilidad Social</li>-->
              </ul>
            </li>
            <li><a href="/boletos"> BOLETOS </a></li>
            <li class="last-mmc dd5">
              <a class="unika" href="/tienda">TIENDA</a
              ><picture>
                <source type="image/webp" :srcset="iconpolygon1" />
                <source :srcset="iconpolygon" type="image/png" />
                <img
                  class="iconpolygon"
                  :src="iconpolygon1"
                  alt="flecha"
                  width="10"
                  height="10"
                />
              </picture>
              <ul class="dropa5">
                <!--<a href="/tienda">
              <li>Entrar a la Tienda</li>
            </a>-->
                <li><a href="/categoria/jersey"> Jerseys </a></li>
                <li><a href="/categoria/chamarras"> Chamarras </a></li>
                <li><a href="/categoria/gorras"> Gorras </a></li>
                <li><a href="/categoria/bufanda"> Bufanda </a></li>
                <li><a href="/categoria/mujer"> Mujer </a></li>
                <li><a href="/categoria/hombre"> Hombre </a></li>
                <li><a href="/categoria/niño"> Niño </a></li>
                <li>
                  <a href="/categoria/edicion-limitada"> Edición Limitada </a>
                </li>
                <li><a href="/categoria/promociones"> Promociones </a></li>
              </ul>
            </li>
          </ul>
          <!-- <div class="ushop-cont">
            <a href="/carrito"
              ><picture>
                <source type="image/webp" :srcset="iconshop1" />
                <source :srcset="iconshop" type="image/png" />
                <img
                  class="btn_iconshop"
                  width="17.65"
                  height="19.41"
                  :src="iconshop1"
                  alt="icon"
                /> </picture
            ></a>
          </div>-->
        </div>

        <div class="sec4-hh">
          <div class="buscador-hh">
            <div id="buscador-hh" class="row">
              <label>
                <input
                  v-model="search_value"
                  type="search"
                  id="input-buscar-hh1"
                  value="s1"
                  class="form-control-hh"
                  @keyup.enter="submit(search_value)"
                />
              </label>
              <!--<button id="btn-buscar-hh" type="button" class="color-bg-1" @click="search(search_value)">-->
              <a
                id="btn-buscar-hh1"
                class="btn-de-busqueda"
                value="s1"
                type="button"
                :href="'/busqueda/' + search_value.replaceAll('/', '-')"
              >
                <picture>
                  <source type="image/webp" :srcset="iconbuscar1" />
                  <source :srcset="iconbuscar" type="image/png" />
                  <img
                    class="iconbuscar"
                    width="17"
                    height="17"
                    :src="iconbuscar1"
                    alt="icon"
                  />
                </picture>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sec1-hh color-bg-10">
      <div class="columna-sec1-hh">
        <h3 class="mt1">SIGUIENTE PARTIDO</h3>
        <span class="linea"></span>
        <!-- PROXIMAMENTE HACER DINAMICO EL H3, TOMAR VALORES DE VARIABLES. -->
        <h3 v-if=" jornadaProx2[0].statusMM !== 7" class="mt2"><!---this.jornadaProx2 !== 'No hay partidos' || jornadaProx2[0].statusMM !== 7-->
          <picture>
            <img
              class="iconpartido"
              width="33"
              height="38"
              :src="url + '/get-calendar-image/' + this.jornadaProx2[0].image"
              alt="equipo"
            />
          </picture>
          
          {{ this.jornadaProx2[0].opname }},
          {{ matchnameday(this.jornadaProx2[0].descrp) }}-{{
            matchday(this.jornadaProx2[0].descrp)
          }}, DE {{ matchmonth(this.jornadaProx2[0].descrp) }}
          {{ hour(this.jornadaProx2Api, this.jornadaProx2[0].typegame) }} HRS
          <picture>
            <source type="image/webp" :srcset="iconarrow1" />
            <source :srcset="iconarrow" type="image/png" />
            <img
              class="iconarrow"
              :src="iconarrow1"
              width="74"
              height="7"
              alt="flecha"
            />
          </picture>
        </h3>
        <h3 class="no-matches" v-else>No hay partidos</h3>
      </div>
      <div class="columna-sec1-hh">
        <section v-if="jornadaProx2[0].statusMM !== 7"><!--this.jornadaProx2 || jornadaProx2[0].statusMM !== 7-->
          <a
            v-if="this.jornadaProx2[0].type == 'Local'"
            class="comprar-boletos"
            :href="this.linkb"
            target="_blank"
            rel="noreferrer noopener"
            >COMPRA TUS BOLETOS AQUÍ</a
          >
        </section>

        <picture>
          <source type="image/webp" :srcset="iconpt1n2" />
          <source :srcset="iconpt1" type="image/png" />
          <a
            href="https://online.caliente.mx/page?member=cimarrones&campaign=DEFAULT&channel=DEFAULT&zone=60503210&lp=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              class="patrocinador1"
              width="92"
              height="46"
              :src="iconpt1n2"
              alt="patrocinio"
          /></a>
        </picture>
      </div>
    </div>

    <div class="sec2-hh">
      <div class="sec3-hh">
        <a href="/">
          <picture>
            <source type="image/webp" :srcset="logo1" />
            <source :srcset="logo" type="image/png" />
            <img
              class="logo-hh"
              :src="logo1"
              alt="flecha"
              width="59"
              height="65"
            /> </picture
        ></a>
      </div>
      <div class="sec4-hh">
        <div class="buscador-hh">
          <div id="buscador-hh" class="row">
            <label>
              <input
                v-model="search_value"
                type="search"
                id="input-buscar-hh2"
                value="s2"
                class="form-control-hh"
                @keyup.enter="submit(search_value)"
              />
            </label>
            <!--<button id="btn-buscar-hh" type="button" class="color-bg-1" @click="search(search_value)">-->
            <a
              id="btn-buscar-hh2"
              class="btn-de-busqueda"
              value="s2"
              type="button"
              :href="'/busqueda/' + search_value.replaceAll('/', '-')"
            >
              <picture>
                <source type="image/webp" :srcset="iconbuscar1" />
                <source :srcset="iconbuscar" type="image/png" />
                <img
                  class="iconbuscar"
                  width="17"
                  height="17"
                  :src="iconbuscar1"
                  alt="icon"
                />
              </picture>
            </a>
          </div>
        </div>
      </div>
      <div class="burger-cont">
        <input id="check1" type="checkbox" />
        <label for="check1">
          <!--<img src="../assets/burger.png" alt="">-->
          <div
            class="hamburger"
            @click="falseMethod"
            v-bind:class="{ 'hamburger--is-open': hamburgerOpen }"
          >
            <div class="hamburger__item hamburger__item--first"></div>
            <div class="hamburger__item hamburger__item--middle"></div>
            <div class="hamburger__item hamburger__item--last"></div>
          </div>
        </label>
        <div class="sec5-hh-mo" id="myDropdown">
          <div class="user-hh-mo" v-if="this.check()">
            <div class="btnslog-mo">
              <div id="buscador-hh" class="row">
                <label>
                  <input
                    v-model="search_value"
                    type="search"
                    id="input-buscar-hh3"
                    value="s3"
                    class="form-control-hh"
                    @keyup.enter="submit(search_value)"
                  />
                </label>
                <a
                  id="btn-buscar-hh3"
                  value="s3"
                  type="button"
                  class="color-bg-1 btn-de-busqueda"
                  :href="'/busqueda/' + search_value.replaceAll('/', '-')"
                >
                  <picture>
                    <source type="image/webp" :srcset="iconbuscar1" />
                    <source :srcset="iconbuscar" type="image/png" />
                    <img
                      class="iconbuscar"
                      width="17"
                      height="17"
                      :src="iconbuscar1"
                      alt="icon"
                    />
                  </picture>
                </a>
              </div>

              <div class="ushop-cont-mo">
                <a
                  v-if="
                    identity.role == 'admin' ||
                    identity.role == 'Legrafica' ||
                    identity.role == 'editor' ||
                    identity.role == 'comunicacion' ||
                    identity.role == 'multimedia'
                  "
                  href="/Administrador"
                  ><picture>
                    <source type="image/webp" :srcset="usernav1" />
                    <source :srcset="usernav" type="image/png" />
                    <img
                      class="btn_iconuser"
                      width="17.65"
                      height="19.41"
                      :src="usernav1"
                      alt=""
                    /> </picture
                ></a>
                <a v-else href="/panel"
                  ><picture>
                    <source type="image/webp" :srcset="usernav1" />
                    <source :srcset="usernav" type="image/png" />
                    <img
                      class="btn_iconuser"
                      width="17.65"
                      height="19.41"
                      :src="usernav1"
                      alt=""
                    /> </picture
                ></a>
                <a @click="shop()"
                  ><picture>
                    <source type="image/webp" :srcset="iconshop1" />
                    <source :srcset="iconshop" type="image/png" />
                    <img
                      class="btn_iconshop"
                      width="17.65"
                      height="19.41"
                      :src="iconshop1"
                      alt="icon"
                    /> </picture
                ></a>
              </div>
            </div>
          </div>
          <!--no esta logeado-->
          <div class="user-hh-mo" v-else>
            <div class="btnslog-mo">
              <div id="buscador-hh" class="row">
                <label>
                  <input
                    v-model="search_value"
                    type="search"
                    id="input-buscar-hh4"
                    value="s4"
                    class="form-control-hh"
                    @keyup.enter="submit(search_value)"
                  />
                </label>
                <a
                  id="btn-buscar-hh4"
                  value="s4"
                  type="button"
                  class="color-bg-1 btn-de-busqueda"
                  :href="'/busqueda/' + search_value.replaceAll('/', '-')"
                  ><picture>
                    <source type="image/webp" :srcset="iconbuscar1" />
                    <source :srcset="iconbuscar" type="image/png" />
                    <img
                      class="iconbuscar"
                      width="17"
                      height="17"
                      :src="iconbuscar1"
                      alt="icon"
                    />
                  </picture>
                </a>
              </div>

              <div class="ushop-cont-mo">
                <a href="/login"
                  ><picture>
                    <source type="image/webp" :srcset="usernav1" />
                    <source :srcset="usernav" type="image/png" />
                    <img
                      class="btn_iconuser"
                      width="17.65"
                      height="19.41"
                      :src="usernav1"
                      alt=""
                    /> </picture
                ></a>
                <!--<a href="/signin"><img class="btn_iconuser" :src="usernav" alt=""></a>-->
                <a @click="shop()"
                  ><picture>
                    <source type="image/webp" :srcset="iconshop1" />
                    <source :srcset="iconshop" type="image/png" />
                    <img
                      class="btn_iconshop"
                      width="17.65"
                      height="19.41"
                      :src="iconshop1"
                      alt="icon"
                    /> </picture
                ></a>
              </div>
            </div>
          </div>

          <div id="menu-hh-mo">
            <ul>
              <li><a href="/">Inicio</a></li>
              <input id="checkMulti" type="checkbox" />
              <li>
                <label for="checkMulti">
                  Juegos
                  <picture>
                    <source
                      type="image/webp"
                      srcset="../assets/webp/arrow-white.webp"
                    />
                    <source
                      srcset="../assets/arrow-white.png"
                      type="image/png"
                    />
                    <img
                      class="iconpolygon"
                      src="../assets/webp/arrow-white.webp"
                      alt="flecha"
                      width="10"
                      height="2"
                    /> </picture
                ></label>
              </li>
              <ul class="supa">
                <li>
                  <a href="/calendarios">
                    Calendario y Resultados<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      />
                    </picture>
                  </a>
                </li>
                <li>
                  <a href="/clasificaciones">
                    Tabla General
                    <picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      />
                    </picture>
                  </a>
                </li>
              </ul>
              <input id="checkMulti1" type="checkbox" />
              <li>
                <label for="checkMulti1">
                  Equipos<picture>
                    <source
                      type="image/webp"
                      srcset="../assets/webp/arrow-white.webp"
                    />
                    <source
                      srcset="../assets/arrow-white.png"
                      type="image/png"
                    />
                    <img
                      class="iconpolygon"
                      src="../assets/webp/arrow-white.webp"
                      alt="flecha"
                      width="10"
                      height="2"
                    /> </picture
                ></label>
              </li>
              <ul class="supa1">
                <li>
                  <a href="/jugadores">
                    Expansión<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      />
                    </picture>
                  </a>
                </li>
                <li>
                  <a href="/jugadores">
                    Premier
                    <picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      />
                    </picture>
                  </a>
                </li>
                <li>
                  <a href="/jugadores">
                    Tercera (TDP)
                    <picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      />
                    </picture>
                  </a>
                </li>
              </ul>
              <input id="checkMulti2" type="checkbox" />
              <li>
                <label for="checkMulti2">
                  Noticias<picture>
                    <source
                      type="image/webp"
                      srcset="../assets/webp/arrow-white.webp"
                    />
                    <source
                      srcset="../assets/arrow-white.png"
                      type="image/png"
                    />
                    <img
                      class="iconpolygon"
                      src="../assets/webp/arrow-white.webp"
                      alt="flecha"
                      width="10"
                      height="2"
                    /> </picture
                ></label>
              </li>
              <ul class="supa2">
                <li>
                  <a href="/blog/expansion">
                    Expansión<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/blog/fuerzas-basicas">
                    Fuerzas Básicas
                    <picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/blog/eventos">
                    Eventos
                    <picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <!--<li><a href="/galeria"
                  >
                    Galerías de fotos
                    <picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      />
                    </picture></a></li>-->
              </ul>
              <li><a href="/galeria"> Galería</a></li>
              <li><a href="/videos"> Videos</a></li>
              <input id="checkMulti3" type="checkbox" />
              <li>
                <label for="checkMulti3">
                  Club
                  <picture>
                    <source
                      type="image/webp"
                      srcset="../assets/webp/arrow-white.webp"
                    />
                    <source
                      srcset="../assets/arrow-white.png"
                      type="image/png"
                    />
                    <img
                      class="iconpolygon"
                      src="../assets/webp/arrow-white.webp"
                      alt="flecha"
                      width="10"
                      height="2"
                    /> </picture
                ></label>
              </li>
              <ul class="supa3">
                <!--  <li>Historia<img class="iconpolygon" src="../assets/arrow-white.png" alt=""></li> -->
                <li>
                  <a href="/filosofia">
                    Filosofía
                    <picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/filosofia#Historia">
                    Historia
                    <picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/estadio">
                    Estadio<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <!--<li>Directiva<img class="iconpolygon" src="../assets/arrow-white.png" alt=""></li>
                                    <li>Responsabilidad Social<img class="iconpolygon" src="../assets/arrow-white.png" alt=""></li>-->
              </ul>
              <li><a href="/boletos"> Boletos</a></li>
              <!-- <a href="/tienda"> <li class="last-mmc">Tienda</li> </a>-->
              <input id="checkMulti4" type="checkbox" />
              <li>
                <label for="checkMulti4">
                  Tienda
                  <picture>
                    <source
                      type="image/webp"
                      srcset="../assets/webp/arrow-white.webp"
                    />
                    <source
                      srcset="../assets/arrow-white.png"
                      type="image/png"
                    />
                    <img
                      class="iconpolygon"
                      src="../assets/webp/arrow-white.webp"
                      alt="flecha"
                      width="10"
                      height="2"
                    /> </picture
                ></label>
              </li>
              <ul class="supa4">
                <li>
                  <a href="/tienda">
                    Nuestra Tienda
                    <picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/categoria/jersey">
                    Jersey<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/categoria/chamarras">
                    Chamarras<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/categoria/gorras">
                    Gorras<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/categoria/bufanda">
                    Bufandas<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/categoria/mujer">
                    Mujer<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/categoria/hombre">
                    Hombre<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/categoria/niños">
                    Niños<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/categoria/promociones">
                    Promociones<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
                <li>
                  <a href="/categoria/edicion-limitada">
                    Edición Limitada<picture>
                      <source
                        type="image/webp"
                        srcset="../assets/webp/arrow-white.webp"
                      />
                      <source
                        srcset="../assets/arrow-white.png"
                        type="image/png"
                      />
                      <img
                        class="iconpolygon"
                        src="../assets/webp/arrow-white.webp"
                        alt="flecha"
                        width="10"
                        height="2"
                      /> </picture
                  ></a>
                </li>
              </ul>
            </ul>
          </div>

          <div class="drop-sm-patro-mo">
            <h3>Siguenos en redes sociales</h3>
            <div class="sm-cont-mo">
              <a
                href="https://www.facebook.com/CimarronesFC/"
                target="_blank"
                rel="noreferrer noopener"
                ><picture>
                  <source type="image/webp" :srcset="fbnav1" />
                  <source :srcset="fbnav" type="image/png" />
                  <img
                    class="btn_smuser f"
                    :src="fbnav1"
                    alt="sm"
                    width="6"
                    height="13.5"
                  />
                </picture>
              </a>
              <a
                href="https://www.instagram.com/cimarronesfc/"
                target="_blank"
                rel="noreferrer noopener"
                ><picture>
                  <source type="image/webp" :srcset="insnav1" />
                  <source :srcset="insnav" type="image/png" />
                  <img
                    class="btn_smuser i"
                    :src="insnav1"
                    alt="sm"
                    width="13"
                    height="13"
                  />
                </picture>
              </a>
              <a
                href="https://mx.linkedin.com/company/cimarronessonora"
                target="_blank"
                rel="noreferrer noopener"
                ><picture>
                  <source type="image/webp" :srcset="innav1" />
                  <source :srcset="innav" type="image/png" />
                  <img
                    class="btn_smuser l"
                    :src="innav1"
                    alt="sm"
                    width="13"
                    height="13"
                  />
                </picture>
              </a>
              <a
                href="https://twitter.com/CimarronesFC"
                target="_blank"
                rel="noreferrer noopener"
                ><img class="btn_smuser t" :src="twnav" alt=""
              /></a>
              <a
                href="https://www.youtube.com/channel/UCvPozguw0umKKLZIBH_jMnQ"
                target="_blank"
                rel="noreferrer noopener"
                ><picture>
                  <source type="image/webp" :srcset="ytnav1" />
                  <source :srcset="ytnav" type="image/png" />
                  <img
                    class="btn_smuser yt"
                    :src="ytnav1"
                    alt="sm"
                    width="13"
                    height="13"
                  />
                </picture>
              </a>
              <a
                href="https://www.tiktok.com/@cimarronesfc?"
                target="_blank"
                rel="noreferrer noopener"
                ><picture>
                  <source type="image/webp" :srcset="ttnav1" />
                  <source :srcset="ttnav" type="image/png" />
                  <img
                    class="btn_smuser tt"
                    :src="ttnav1"
                    alt="sm"
                    width="13"
                    height="13"
                  />
                </picture>
              </a>
            </div>

            <div class="patro-cont-mo">
              <div class="patro-col uno">
                <a
                  href="https://www.caliente.mx/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="../assets/Caliente.png"
                    alt="patro"
                    class="patro-logo uno"
                  />
                </a>
                <a href="/" target="_blank" rel="noreferrer noopener">
                  <img
                    src="../assets/Tecate.png"
                    alt="patro"
                    class="patro-logo due"
                  />
                </a>
                <a
                  href="https://www.elimparcial.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="../assets/El_Imparcial.png"
                    alt="patro"
                    class="patro-logo tre"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/UK.png"
                    alt="patro"
                    class="patro-logo quattro"
                  />
                </a>
              </div>
              <div class="patro-col due">
                <a href="/">
                  <img
                    src="../assets/Nueva_España.png"
                    alt="patro"
                    class="patro-logo cincue"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/Electrolit.png"
                    alt="patro"
                    class="patro-logo sei"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/Kosako.png"
                    alt="patro"
                    class="patro-logo sette"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/Comex.png"
                    alt="patro"
                    class="patro-logo octo"
                  />
                </a>
              </div>
              <div class="patro-col due">
                <a href="/">
                  <img
                    src="../assets/Invasora.png"
                    alt="patro"
                    class="patro-logo cincue"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/volaris_foo.png"
                    alt="patro"
                    class="patro-logo sei"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/Buquibichi.png"
                    alt="patro"
                    class="patro-logo sette"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/MT.png"
                    alt="patro"
                    class="patro-logo octo"
                  />
                </a>
              </div>
              <div class="patro-col due">
                <a href="/">
                  <img
                    src="../assets/BH.png"
                    alt="patro"
                    class="patro-logo cincue"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/San_Jose.png"
                    alt="patro"
                    class="patro-logo sei"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/Pipeso.png"
                    alt="patro"
                    class="patro-logo sette"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/Naceb.png"
                    alt="patro"
                    class="patro-logo octo"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/Hermogas.png"
                    alt="patro"
                    class="patro-logo octo"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/Keuka.png"
                    alt="patro"
                    class="patro-logo octo"
                  />
                </a>
              </div>
              <div class="patro-col due">
                <a href="/">
                  <img
                    src="../assets/Aviles.png"
                    alt="patro"
                    class="patro-logo cincue"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/Cima.png"
                    alt="patro"
                    class="patro-logo sei"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/XPG.png"
                    alt="patro"
                    class="patro-logo sette"
                  />
                </a>
                <a href="/">
                  <img
                    src="../assets/Megacable.png"
                    alt="patro"
                    class="patro-logo octo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sec5-hh" id="myDropdown">
      <div id="menu-hh">
        <ul class="menucito">
          <li><a href="/">INICIO</a></li>
          <li class="dd1">
            JUEGOS<picture>
              <source type="image/webp" :srcset="iconpolygon1" />
              <source :srcset="iconpolygon" type="image/png" />
              <img
                class="iconpolygon"
                :src="iconpolygon1"
                alt="flecha"
                width="10"
                height="10"
              />
            </picture>
            <ul class="dropa">
              <li><a href="/calendarios"> Calendario y Resultados </a></li>
              <li><a href="/clasificaciones"> Tabla General </a></li>
            </ul>
          </li>
          <li class="dd2">
            EQUIPOS
            <picture>
              <source type="image/webp" :srcset="iconpolygon1" />
              <source :srcset="iconpolygon" type="image/png" />
              <img
                class="iconpolygon"
                :src="iconpolygon1"
                alt="flecha"
                width="10"
                height="10"
              />
            </picture>
            <ul class="dropa2">
              <li><a href="/jugadores"> Expansión </a></li>
              <li><a href="/jugadores/premier"> Premier </a></li>
              <li><a href="/jugadores/tercera"> Tercera (TDP) </a></li>
            </ul>
          </li>
          <li class="dd3">
            <a class="unika" href="#ultimasNoticias"> NOTICIAS</a>
            <picture>
              <source type="image/webp" :srcset="iconpolygon1" />
              <source :srcset="iconpolygon" type="image/png" />
              <img
                class="iconpolygon"
                :src="iconpolygon1"
                alt="flecha"
                width="10"
                height="10"
              />
            </picture>
            <ul class="dropa3">
              <li><a href="/blog/expansion"> Expansión </a></li>
              <li><a href="/blog/fuerzas-basicas"> Fuerzas Básicas </a></li>
              <li><a href="/blog/eventos"> Eventos </a></li>
              <!--  <li><a href="/galeria">Galerías de fotos</a></li>-->
            </ul>
          </li>
          <li><a href="/galeria"> GALERÍA </a></li>
          <li><a href="/videos"> VIDEOS </a></li>
          <li class="dd4">
            CLUB
            <picture>
              <source type="image/webp" :srcset="iconpolygon1" />
              <source :srcset="iconpolygon" type="image/png" />
              <img
                class="iconpolygon"
                :src="iconpolygon1"
                alt="flecha"
                width="10"
                height="10"
              />
            </picture>
            <ul class="dropa4">
              <!--  <li>Historia</li> -->
              <li><a href="/filosofia">Filosofía</a></li>
              <li><a href="/filosofia#Historia">Historia</a></li>

              <li><a href="/estadio"> Estadio</a></li>
              <!--<li>Directiva</li>
                                    <li>Responsabilidad Social</li>-->
            </ul>
          </li>
          <li><a href="/boletos"> BOLETOS </a></li>
          <li class="last-mmc dd5">
            <a class="unika" href="/tienda"
              >TIENDA<picture>
                <source type="image/webp" :srcset="iconpolygon1" />
                <source :srcset="iconpolygon" type="image/png" />
                <img
                  class="iconpolygon"
                  :src="iconpolygon1"
                  alt="flecha"
                  width="10"
                  height="10"
                /> </picture
            ></a>
            <ul class="dropa5">
              <!--<a href="/tienda">
                                      <li>Entrar a la Tienda</li>
                                    </a>-->
              <li><a href="/categoria/jersey"> Jerseys </a></li>
              <li><a href="/categoria/chamarras"> Chamarras </a></li>
              <li><a href="/categoria/gorras"> Gorras </a></li>
              <li><a href="/categoria/bufanda"> Bufanda </a></li>
              <li><a href="/categoria/mujer"> Mujer </a></li>
              <li><a href="/categoria/hombre"> Hombre </a></li>
              <li><a href="/categoria/niño"> Niño </a></li>
              <li>
                <a href="/categoria/edicion-limitada"> Edición Limitada </a>
              </li>
              <li><a href="/categoria/promociones"> Promociones </a></li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="user-hh" v-if="this.check()">
        <div class="btnslog">
          <div class="sm-cont">
            <a
              href="https://www.facebook.com/CimarronesFC/"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="fbnav1" />
                <source :srcset="fbnav" type="image/png" />
                <img
                  class="btn_smuser f"
                  :src="fbnav1"
                  alt="sm"
                  width="6"
                  height="13"
                /> </picture
            ></a>
            <a
              href="https://www.instagram.com/cimarronesfc/"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="insnav1" />
                <source :srcset="insnav" type="image/png" />
                <img
                  class="btn_smuser i"
                  :src="insnav1"
                  alt="sm"
                  width="13"
                  height="13"
                /> </picture
            ></a>
            <a
              href="https://mx.linkedin.com/company/cimarronessonora"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="innav1" />
                <source :srcset="innav" type="image/png" />
                <img
                  class="btn_smuser l"
                  :src="innav1"
                  alt="sm"
                  width="13"
                  height="13"
                /> </picture
            ></a>
            <a
              href="https://twitter.com/CimarronesFC"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="twnav1" />
                <source :srcset="twnav" type="image/png" />
                <img
                  class="btn_smuser t"
                  :src="twnav1"
                  alt="sm"
                  width="13"
                  height="13"
                /> </picture
            ></a>
            <a
              href="https://www.youtube.com/channel/UCvPozguw0umKKLZIBH_jMnQ"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="ytnav1" />
                <source :srcset="ytnav" type="image/png" />
                <img
                  class="btn_smuser yt"
                  :src="ytnav1"
                  alt="sm"
                  width="13"
                  height="13"
                /> </picture
            ></a>
            <a
              href="https://www.tiktok.com/@cimarronesfc?"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="ttnav1" />
                <source :srcset="ttnav" type="image/png" />
                <img
                  class="btn_smuser tt"
                  :src="ttnav1"
                  alt="sm"
                  width="13"
                  height="13"
                /> </picture
            ></a>
          </div>

          <div class="ushop-cont">
            <a
              v-if="
                identity.role == 'admin' ||
                identity.role == 'Legrafica' ||
                identity.role == 'editor' ||
                identity.role == 'comunicacion' ||
                identity.role == 'multimedia'
              "
              href="/Administrador"
              ><picture>
                <source type="image/webp" :srcset="usernav1" />
                <source :srcset="usernav" type="image/png" />
                <img
                  class="btn_iconuser"
                  width="17.65"
                  height="19.41"
                  :src="usernav1"
                  alt="sm"
                /> </picture
            ></a>
            <a v-else href="/panel"
              ><picture>
                <source type="image/webp" :srcset="usernav1" />
                <source :srcset="usernav" type="image/png" />
                <img
                  class="btn_iconuser"
                  width="17.65"
                  height="19.41"
                  :src="usernav1"
                  alt="sm"
                /> </picture
            ></a>

            <a @click="shop()"
              ><picture>
                <source type="image/webp" :srcset="iconshop1" />
                <source :srcset="iconshop" type="image/png" />
                <img
                  class="btn_iconshop"
                  width="17.65"
                  height="19.41"
                  :src="iconshop1"
                  alt="icon"
                /> </picture
            ></a>
          </div>
        </div>
      </div>
      <!--no esta logeado-->
      <div class="user-hh" v-else>
        <div class="btnslog">
          <div class="sm-cont">
            <a
              href="https://www.facebook.com/CimarronesFC/"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="fbnav1" />
                <source :srcset="fbnav" type="image/png" />
                <img
                  class="btn_smuser f"
                  :src="fbnav1"
                  alt="sm"
                  width="6"
                  height="13"
                /> </picture
            ></a>
            <a
              href="https://www.instagram.com/cimarronesfc/"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="insnav1" />
                <source :srcset="insnav" type="image/png" />
                <img
                  class="btn_smuser i"
                  :src="insnav1"
                  alt="sm"
                  width="13"
                  height="13"
                /> </picture
            ></a>
            <a
              href="https://mx.linkedin.com/company/cimarronessonora"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="innav1" />
                <source :srcset="innav" type="image/png" />
                <img
                  class="btn_smuser l"
                  :src="innav1"
                  alt="sm"
                  width="13"
                  height="13"
                /> </picture
            ></a>
            <a
              href="https://twitter.com/CimarronesFC"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="twnav1" />
                <source :srcset="twnav" type="image/png" />
                <img
                  class="btn_smuser t"
                  :src="twnav1"
                  alt="sm"
                  width="13"
                  height="13"
                /> </picture
            ></a>
            <a
              href="https://www.youtube.com/channel/UCvPozguw0umKKLZIBH_jMnQ"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="ytnav1" />
                <source :srcset="ytnav" type="image/png" />
                <img
                  class="btn_smuser yt"
                  :src="ytnav1"
                  alt="sm"
                  width="13"
                  height="13"
                /> </picture
            ></a>
            <a
              href="https://www.tiktok.com/@cimarronesfc?"
              target="_blank"
              rel="noreferrer noopener"
              ><picture>
                <source type="image/webp" :srcset="ttnav1" />
                <source :srcset="ttnav" type="image/png" />
                <img
                  class="btn_smuser tt"
                  :src="ttnav1"
                  alt="sm"
                  width="13"
                  height="13"
                /> </picture
            ></a>
          </div>

          <div class="ushop-cont">
            <a href="/login"
              ><picture>
                <source type="image/webp" :srcset="usernav1" />
                <source :srcset="usernav" type="image/png" />
                <img
                  class="btn_iconuser"
                  width="17.65"
                  height="19.41"
                  :src="usernav1"
                  alt=""
                /> </picture
            ></a>
            <!--<a href="/signin"><img class="btn_iconuser" :src="usernav" alt=""></a>-->
            <a @click="shop()"
              ><picture>
                <source type="image/webp" :srcset="iconshop1" />
                <source :srcset="iconshop" type="image/png" />
                <img
                  class="btn_iconshop"
                  width="17.65"
                  height="19.41"
                  :src="iconshop1"
                  alt="icon"
                /> </picture
            ></a>
          </div>
        </div>
      </div>
    </div>

    <!--
        <div id="user-img" v-if="msg == 'success'">
           <img :src="this.image(this.identity.image)"  alt="imagen de usuario">
        </div>
      
        <h1 v-if="msg=='success'" id='user-name-img' class="color-6" >¡Hola,  <span class="color-1"> {{name | split }} </span> ! </h1>
        <h1 v-else id='user-name' class="color-6" >¡Hola,  <span class="color-1"> {{name | split }} </span> ! </h1>
        
        
            <div class="center pb-4">
                    <b-btn v-b-modal.modal-logout variant="outline-secondary"  class="logout">Cerrar Sesión</b-btn>
                    <b-modal id="modal-logout" centered hide-footer hide-header 
                    body-class="p-0" dialog-class="px-5" content-class='border-0'>
                        <div class="d-block text-center">
                            <img :src="closeIcon" class="icon3 pt-5"><br>
                            <p class="modalHeader pt-3 color-6" >Cerrar sesión</p>
                            <p class="modalBody color-grey">¿Estás seguro que deseas salir?</p>
                        </div>
                        <div class="wrapper center">
                            <b-button class="one  py-3 btnleft btns" @click="$bvModal.hide('modal-logout')">Cancelar</b-button>
                            <b-button class="two  btnright btns" @click="logout()">Confirmar</b-button>
                        </div>
                    </b-modal>
                </div>

                -->
  </div>
</template>

<script>
import es from "dayjs/locale/es";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { mapActions } from "vuex";
import iconshop from "../assets/frame3.svg";
import iconshop1 from "../assets/frame3.svg";

import iconpolygon from "../assets/cimarrones/polygon1.png";
import iconpolygon1 from "../assets/webp/polygon1.webp";

import iconbuscar from "../assets/cimarrones/iconbuscar.png";
import iconbuscar1 from "../assets/webp/iconbuscar.webp";

import iconuser from "../assets/cimarrones/iconuser.png";

import iconpt1 from "../assets/caliente_1.svg";
import iconpt1n2 from "../assets/caliente_1.svg";

import iconarrow from "../assets/cimarrones/Arrow_1.png";
import iconarrow1 from "../assets/webp/Arrow_1.webp";

import iconpartido from "../assets/logo-tepa.png";

import logo from "../assets/Cimarrones-mx.png";
import logo1 from "../assets/webp/logo-cim.webp";

import i_logout from "../assets/iconocerrar.png";

import usernav from "../assets/user-nav.svg";
import usernav1 from "../assets/user-nav.svg";

import fbnav from "../assets/fb-nav.png";
import insnav from "../assets/ins-nav.png";
import innav from "../assets/in-nav.png";
import twnav from "../assets/tw-nav.png";
import ytnav from "../assets/yt-nav.png";
import ttnav from "../assets/tt-nav.png";
import fbnav1 from "../assets/webp/fb-nav.webp";
import insnav1 from "../assets/webp/ins-nav.webp";
import innav1 from "../assets/webp/in-nav.webp";
import twnav1 from "../assets/webp/tw-nav.webp";
import ytnav1 from "../assets/webp/yt-nav.webp";
import ttnav1 from "../assets/webp/tt-nav.webp";

import ls from "../services/login.service";

export default {
  name: "Header",
  props: {
    name: {
      required: true,
    },
  },
  data() {
    return {
      linkb: "https://boletomovil.com/cimarrones-sonora",
      iconshop: iconshop,
      iconshop1: iconshop1,
      iconpolygon: iconpolygon,
      iconpolygon1: iconpolygon1,
      iconbuscar: iconbuscar,
      iconbuscar1: iconbuscar1,
      iconuser: iconuser,
      iconpt1: iconpt1,
      iconarrow: iconarrow,
      iconarrow1: iconarrow1,
      iconpt1n2: iconpt1n2,
      iconpartido: iconpartido,
      closeIcon: i_logout,
      usernav: usernav,
      usernav1: usernav1,
      logo: logo,
      logo1: logo1,
      fbnav: fbnav,
      insnav: insnav,
      innav: innav,
      twnav: twnav,
      ytnav: ytnav,
      ttnav: ttnav,
      fbnav1: fbnav1,
      insnav1: insnav1,
      innav1: innav1,
      twnav1: twnav1,
      ytnav1: ytnav1,
      ttnav1: ttnav1,
      search_value: "",
      id: 0,
      msg: "success",
      primero: 0,
      hamburgerOpen: false,
      isSticky: false,
      stickyClass: "is_sticky",
      scrollPosition: 0,

      tournament: "Clausura 24",
    };
  },
  created() {
    let payload = {
      idTorneo: 2,
      idDivision: 20,
      idTemporada: 74,
      idClub: 10721,
    };
    this.getPartidosClub4(payload);
    this.getCalendar();
    this.findByLigaExp1();
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    token() {
      return this.$store.getters["admin/getToken"];
    },
    identity() {
      return this.$store.getters["admin/getIdentity"];
    },

    url() {
      return this.$store.getters["main/baseURL"];
    },
    datec() {
      return dayjs().format("YYYY-MM-DD");
    },
    jornadaProx2Admi() {
      let partidos = this.$store.getters["calendar/data5"]; 
      let arrAD = this.$store.getters["partidos/data6"];

      let tor = this.tournament;
      let arr = [];
      dayjs.extend(customParseFormat);
      let date = dayjs().format("YYYY-MM-DD");
      for (let i = 0; i < partidos.length; i++) {
        if (partidos[i].name_tournament == tor) { 
          for (let j = 0; j < arrAD.length; j++) {
            if( partidos[i].typegame == arrAD[j].jornada ){
              let aa = {
                ...partidos[i],
                statusMM: arrAD[j].idEstatusMinutoAMinuto
              }
              arr.push(aa);
            }
          }
         
        }
      }
      if(arr.length == 0){
        return null
      }
      arr = arr.reverse();
      for (let j = 0; j < arr.length; j++) {
        if (j == arr.length - 1) {
          let first_y_elements = arr.slice(0, -1);
          let remaining_elements = arr.slice(-1, arr.length);
          let narr = [...remaining_elements, ...first_y_elements];
          return narr;
        } else {
          if (arr[j].descrp > date) {
            console.log(arr[j].descrp);
            let i = j;
            let ye = i % arr.length;
            let first_y_elements = arr.slice(0, ye);
            let remaining_elements = arr.slice(ye, arr.length);
            let narr = [...remaining_elements, ...first_y_elements];
            return narr; /**/
          }
        }
      }
    },
    sdd(){
      return this.$store.getters["partidos/data6"];
    },
    jornadaProx2Api() {
      let arr = this.$store.getters["partidos/data6"];
      let partidosAD = this.$store.getters["calendar/data5"];

      dayjs.extend(customParseFormat);
      let date = dayjs().format("YYYY-MM-DD");
      let partidos = [];
      for (let i = 0; i < arr.length; i++) {
        let type = "Local";
        if (arr[i].clubLocal !== "Cimarrones de Sonora FC") {
          type = "Visita";
        }
        let opname;
        if (arr[i].clubLocal !== "Cimarrones de Sonora FC") {
          opname = arr[i].clubLocal;
        } else if (arr[i].clubVisita !== "Cimarrones de Sonora FC") {
          opname = arr[i].clubVisita;
        }
        let imadata;

        for (let j = 0; j < partidosAD.length; j++) {
          if (opname == partidosAD[j].opname) {
            imadata = partidosAD[j].image;
          }
        }

        let data = {
          opname: opname,
          descrp: arr[i].fecha,
          hr: arr[i].hora,
          horaLocal: arr[i].horaLocal,
          type: type,
          image: imadata,
          jornada: arr[i].jornada,
          typegame: arr[i].jornada,
          statusMM: arr[i].idEstatusMinutoAMinuto,
        };
        partidos.push(data);
      }
      
      //partidos = partidos.reverse();
      for (let i = 0; i < partidos.length; i++) {
        if (i == partidos.length - 1) {
          let first_y_elements = partidos.slice(0, -1);
          let remaining_elements = partidos.slice(-1, partidos.length);
          let narr = [...remaining_elements, ...first_y_elements];
          return narr;
        } else {
          if (partidos[i].descrp > date) {
            let y = i % partidos.length;
            let first_y_elements = partidos.slice(0, y);
            let remaining_elements = partidos.slice(y, partidos.length);
            let narr = [...remaining_elements, ...first_y_elements];
            return narr;
          }
        }
      }
    },
    jornadaProx2() {
      if (this.jornadaProx2Admi !== null ) { 
        return this.jornadaProx2Admi;
      } else if (
        (this.jornadaProx2Admi !== null ) &&
        this.jornadaProx2Api == "No hay partidos"
      ) { 
        return this.jornadaProx2Admi;
      } else if (
        (this.jornadaProx2Api !== null ) &&
        (this.jornadaProx2Admi == null )
      ) { 
        return this.jornadaProx2Api;
      } else { 
        return "No hay partidos";
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions("calendar", ["getCalendar"]),
    ...mapActions("calendar", ["findByLigaExp1"]),
    ...mapActions("calendar", ["getCalendarById"]),
    ...mapActions("calendar", ["getCalendarImage"]),
    ...mapActions("partidos", ["getPartidosClub4"]),

    hour(array, jornada) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].jornada == jornada) {
          let hr = array[i].horaLocal;
         
          let hr2 = hr.split(":");
          return hr2[0] + ":" + hr2[1];
          //return array[i].horaLocal
        }
      }
    },

    regexImg(txt) {
      let s = txt.split(".");
      return s[0] + ".webp";
    },

    regexImgFiles(txt) {
      let s = txt.split(",")[0];
      let f = s.split("/")[1];

      let k = f.split(";")[0];
      return k;
      //return s[0]+'.webp'
    },

    falseMethod: function () {
      this.hamburgerOpen = !this.hamburgerOpen;
    },

    submit(value) {
      // console.log('cheems '+value)
      //let route = this.$router.replace('/busqueda/'+value);
      //location = "https://www.cimarronesdesonora.xyz/busqueda/";
      //return  route.go(0)
      let ar = value.replaceAll("/", "-");
      console.log(ar);
      return window.location.replace("/busqueda/" + ar);
    },

    check() {
      let checkIdentity = ls.checkIdentity();
      return checkIdentity;
    },
    logout: function () {
      delete localStorage.token;
      delete localStorage.ath;
      this.wait();
    },
    wait: function () {
      setTimeout(() => this.$router.push("/"), 200);
    },
    image: function (img) {
      let src = this.url + "/get-user-image/" + img;
      this.status(img);
      return src;
    },
    status: async function (img) {
      let userimg = "";
      try {
        userimg = await this.$store.dispatch("main/getImage", {
          image: img,
          option: "user",
        });
        if (userimg.data.status == "error") {
          this.msg = "error";
        } else {
          this.msg = "success";
        }
      } catch (err) {
        this.msg = "error";
      }
    },
    home: function () {
      this.$router.push("/home").catch((err) => {}); //
    },

    perfil: function () {
      alert("ir al perfil " + this.identity.name);
    },
    shop: function () {
      setTimeout(() => this.$router.push("/carrito"), 200);
    },
    login: function () {
      setTimeout(() => this.$router.push("/login"), 200);
    },

    signin: function () {
      setTimeout(() => this.$router.push("/signin"), 200);
    },

    refresh: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    search: function (value) {
      return "/busqueda/" + value;
      // alert('Buscar: '+value)
    },
    matchday: function (descrp) {
      let fecha = descrp.split("-");
      let dd = fecha[2];
      return (fecha = dd);
    },

    matchmonth: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let match = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MMMM-YYYY");
      let fecha = match.split("-");
      let mm = fecha[1];
      return (fecha = mm);
    },

    matchnameday: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let match = dayjs(descrp, "YYYY-MM-DD").locale(es).format("dddd-MM-YYYY");
      let fecha = match.split("-");
      let dddd = fecha[0];
      return (fecha = dddd);
    },

    /*  getCalendarById:async function(id){
        let result = await this.$store.dispatch("calendar/getCalendar", {"option":"calendar", "id":id});
        //console.log(result)
        this.info = result
        return result;
        },*/
    getCalendar: async function () {
      let result = await this.$store.dispatch("calendar/getCalendar", {
        option: "calendario",
      });
      //console.log(result)
      this.info = result;
      return result;
    },

    findByLigaExp1: async function () {
      let result = await this.$store.dispatch("calendar/findByLigaExp1", {
        option: "calendar/liga",
        id: 1,
      });
      //console.log(result)
      this.info = result;
      return result;
    },
    handleScroll(event) {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
  },

  filters: {
    split: (value) => {
      if (!value) return "";
      value = value.toString();
      let splitStr = value.toLowerCase().split(" ");
      let len = splitStr.length;
      let i;

      for (i = 0; i < len; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      let nameCap = splitStr.join(" ");
      let newName = nameCap.split(" ").slice(0, -1).join(" "); //cantidad de palabras quitadas.

      return newName;
    },
  },
};
</script>

<style scoped>
#menu-sticky {
  display: none;
}
#menu-sticky .ushop-cont {
  margin: 0 1vw;
}
#menu-sticky .ushop-cont .btn_iconshop {
  transition: 0.5s;
  padding: 0.3vw;
  width: 1.419271vw;
  height: 1.419271vw;
  cursor: pointer;
}
#menu-sticky .ushop-cont .btn_iconshop:hover {
  background: red;
}
ul.menucito a.unika {
  color: white !important;
  text-decoration-color: transparent !important;
  padding: 1vw 0.1vw;
}
#menu-sticky.is_sticky {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  width: 100%;
  -webkit-animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  background: #002340;
}
#menu-sticky .sec2-hh {
  display: flex;
  border-bottom: none;
  align-items: center;
  height: 4vw;
}
#menu-sticky #buscador-hh {
  margin-left: 0;
}
#menu-sticky #menu-hh ul.dropa,
#menu-sticky #menu-hh ul.dropa2,
#menu-sticky #menu-hh ul.dropa3,
#menu-sticky #menu-hh ul.dropa4,
#menu-sticky #menu-hh ul.dropa5 {
  top: 5.7vw;
}
#menu-sticky .logo-hh {
  margin: 0;
  width: 3.073vw;
  height: 3.185vw;
}
label {
  display: inline-block;
  margin: 0;
}
@media (max-width: 768px) {
  #menu-sticky.is_sticky {
    display: none;
  }
}
</style>

