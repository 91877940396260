<template>
  <transition name="modal-fade">
  <div class="modal2-backdrop" @click="close">
    <div class="modal2" @click.stop="">
      <header class="modal2-header">
        <button
          type="button"
          class="btn-close"
          @click="close"
        >
          x
        </button>
      </header>

      <section class="modal2-body">
        <slot name="body">
          <form   @submit.prevent="
                enviarMail(
                 nombre, medio, correo, comentarios, option
                )
              ">
             <div class="contact-form-body">
            <h1 class="titulo-contact-form">ENVIANOS UN MENSAJE A <a href="mailto:hola@cimarronesfc.com.mx">hola@cimarronesfc.com.mx</a>  </h1>
            <h2 class="titulo-contact-form">Tambien puedes utilizar nuestro formulario</h2>
             
            <div class="row-contact">
              <div class="input-contact">
                <h6>NOMBRE</h6>
                <input type="text" name="nombre" id="text-field-contact" required v-model="nombre">
              </div>
              <div class="input-contact">
                <h6>CELULAR</h6>
                <input type="text" name="medio" id="text-field-contact"  
                    minlength="10"
                    maxlength="10"
                    size="10"
                    pattern="\d*"  
                    required v-model="medio">
              </div>
              <div class="input-contact">
                <h6>CORREO</h6>
                <input type="email" name="email" id="text-field-contact" required v-model="correo">
              </div>
            </div>
            <div class="row-contact">
              <div class="input-contact">
                <h6>COMENTARIOS</h6>
                <textarea name="comentarios" id="text-contact" cols="100" rows="10" required v-model="comentarios"></textarea>
              </div>
            </div>
            
            <div class="row-contact sub">
              <input type="submit"  value="ENVIAR" class="submit-contact"> 
            </div>
            
           <div v-if="status =='success'">
                <p class="cart_msg cart_success">{{this.msg}}</p>
             </div>
              <div v-if="status =='error'">
                <p class="cart_msg cart_error">{{this.msg}}</p>
             </div>
           
             
     
               
          


              </div>
          </form>
         
        </slot>
      </section>
    </div>
  </div>
  </transition>
</template>


<script>
  export default {
    name: 'Modal',
    props:{
      option:String
    },
    data(){
      return{
        nombre:"",
        comentarios:"",
        medio:"",
        correo:"",
        status:"",
        msg:"",
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
      delStatus: function () {
        setTimeout(() => this.delMsgs()
        ,5000);
        setTimeout(() => this.close()
        ,3000);
      },
      delMsgs: function(){
        this.status=""
        this.msg=""
      },
      enviarMail: async function(nombre, medio, correo, comentarios, option){

        
         this.msg = ''
         this.status =''
          if(nombre == undefined || nombre=='' ||  nombre == null ){
              this.msg = 'El campo nombre no es valido.'
              this.status ='error'
          }else if(medio == undefined || medio=='' ||  medio == null ){
              this.msg = 'El campo medio no es valido.'
              this.status ='error'
          }else if( correo == undefined  || correo=='' ||  correo == null){
              this.msg = 'El campo correo no es valido.'
              this.status ='error'
          }else if(comentarios == undefined || comentarios =='' ||  comentarios == null) {
              this.msg = 'El campo comentarios no es valido.'
              this.status ='error'
          }else{

               let data = {
                "comentarios":comentarios,
                "correo":correo,
                "medio":medio,
                "nombre":nombre,
                "option":option
              }
             let response = await this.$store.dispatch("admin/enviarMailContacto", data);
             if(response.status =='success'){
               this.status='success'
               this.msg='Mensaje enviado con exito.'
               this.nombre =""
               this.medio="",
               this.correo="",
               this.comentarios=""
              this.delStatus()
             }else{
                this.status='error'
                this.msg='Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.'
             }
          }
  
      }
    },
  };
</script>

<style scoped>
.modal2 {
    background: transparent;
    border-radius: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    width: 53.854vw;
    height: 41.813vw;
}.btn-close {
    position: relative;
    top: 2vw;
    margin-left: auto;
    border-radius: 5vw;
    z-index: 9999;
    background-color: #ce1526;
}.modal2-header {
    position: revert;
    /* border-bottom: 1px solid #eeeeee; */
}h1.titulo-contact-form {
    font-size: 1.4vw;
    line-height: 2.396vw;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: #032A4B;
    font-family: 'Rajdhani';
    margin-bottom: 3vw;
}h1.titulo-contact-form a {
 
    color: #B70D1D;
  
}h2.titulo-contact-form {
    font-size: 1.2vw;
    line-height: 2.396vw;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: #032A4B;
    font-family: 'Rajdhani';
}.row-contact {
    display: flex;
    justify-content: space-between;
}.modal2-body {
    position: relative;
    height: auto;
    padding: 4.406vw 5.313vw;
    background: white;
    overflow-x: auto;
}.input-contact {
    margin-bottom: 2.292vw;
}.input-contact h6 {
    font-size: 0.781vw;
    line-height: 1.458vw;
    text-transform: uppercase;
    color: #B70D1D;
    margin-bottom: 1.292vw;
}input#text-field-contact {
    width: 13.958vw;
    height: 3.646vw;
    font-size: 1vw;
}input#text-contact {
    width: 43.802vw;
    height: 10.938vw;
}.row-contact.sub {
    justify-content: flex-end;
}input.submit-contact {
    width: 17.083vw;
    background-repeat: no-repeat;
    background-position-y: 1.7vw;
    background-position-x: 0;
    background-size: 0;
}textarea#text-contact {
    height: 13vw;
    padding: 1vw;
    width: 100%;
}input.submit-contact:hover {
    background-position-x:12.74vw;
    background-size: 3.073vw;
    padding-right: 10vw;
}
input.submit-contact:active, input.submit-contact:focus{
  background: #B70D1D;
}

@media (max-width:768px) {
  .modal2 {
    width: 77.854vw;
    height: 130.813vw;
}.btn-close {
    position: absolute;
    top: 14vw;
    right: 6.5vw;
}.row-contact {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}textarea#text-contact {
    width: 64.01vw;
}input#text-field-contact {
    width: 64.01vw;
    height: 11.646vw;
}h1.titulo-contact-form {
    font-size: 6.039vw;
    line-height: 6.039vw;
}.input-contact h6 {
    font-size: 3.140vw;
    line-height: 3.140vw;
    margin-bottom: 2.292vw;
    margin-top: 6.292vw;
}.modal2-body {
    padding: 12.56vw 5.313vw;
}input.submit-contact  {
    width: 100%;
    background-position-y: 5.7vw;
}input.submit-contact:hover {
    background-position-x: 50.74vw;
    background-size: 8.073vw;
    padding-right: 46vw;
}
}
</style>